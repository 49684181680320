import React, {useEffect, useState} from 'react';
import endpoints from '../constants/endpoints';
import {Auth, IAuth} from "../model/IAuthModel";
import axios, { AxiosResponse } from 'axios';


function AuthComponent() {
    const [data, setData] = useState<Auth>();

    useEffect(() => {
        axios
            .get(endpoints.auth)
            .then((res: AxiosResponse<IAuth>) => {
                // @ts-ignore
                setData(new Auth(res.data));
            })
    }, []);

    return (
        <div className="auth">
            <p>realm: {data?.realm}</p>
            <p>token-service: {data?.["token-service"]}</p>
            <p>account-service: {data?.["account-service"]}</p>
            <p>tokens-not-before: {data?.["tokens-not-before"]}</p>
        </div>
    );
}

export default AuthComponent;