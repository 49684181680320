
export interface IAuth {
    "realm": string
    "public_key": string
    "token-service": string
    "account-service": string
    "tokens-not-before": number
}

export class Auth {
    private "_realm": string
    private "_public_key": string
    private "_token-service": string
    private "_account-service": string
    private "_tokens-not-before": number

    constructor(data: IAuth) {
        Object.assign(this, data)
    }


    get realm(): string {
        return this._realm;
    }

    set realm(value: string) {
        this._realm = value;
    }

    get public_key(): string {
        return this._public_key;
    }

    set public_key(value: string) {
        this._public_key = value;
    }

    get "token-service"(): string {
        return this["_token-service"];
    }

    set "token-service"(value: string) {
        this["_token-service"] = value;
    }

    get "account-service"(): string {
        return this["_account-service"];
    }

    set "account-service"(value: string) {
        this["_account-service"] = value;
    }

    get "tokens-not-before"(): number {
        return this["_tokens-not-before"];
    }

    set "tokens-not-before"(value: number) {
        this["_tokens-not-before"] = value;
    }
}